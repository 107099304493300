import './App.css';
// import NavBar from './components/NavBar';
// import Intro from './components/Intro';
// import About from './components/About';
// import Skills from './components/Skills';
// import Portfolio from './components/Portfolio';
// import Contact from './components/Contact';
// import Footer from './components/Footer';
// import { useEffect, useState } from 'react';

function App() {
  return <div className="container">{'{ The site is being developed👨‍💻}'}</div>;
}

export default App;
